// submitMeCreator.js
'use strict';
import fetch from '../resource/customFetch.js';
import setJoinDraftData from '../action/setJoinDraftData.js';

import getMeData from '../selector/getMeData.js';

import getResourceUrl from '../resource/getResourceUrl.js';
import { getHeaders } from '../resource/fetchOptionHeader.js';
import handleFetchError from '../resource/handleFetchError.js';
import { JoinField } from '../resource/joinConstants.js';
import getTranslationKey from '../resource/getTranslationKey.js';

import {
  SET_NETWORKING_FETCHING,
  SET_NETWORKING_SUCCESS,
  SET_NETWORKING_ERROR,
  MERGE_OPERATION_DATA,
} from '../ActionTypes.js';

const ErrorMessageFieldAndJoinFieldMap = {
  country: JoinField.COUNTRY,
  gender: JoinField.GENDER,
};

/**
 * Submit me creator
 * @kind action
 * @param {string} {country} - user country.
 * @param {string} {gender} - user gender.
 * @param {string} {referralCode} - referral code.
 * @param {string} {kycId} - kyc id.
 * @param {string} {contractId} - contract id.
 * @return {Promise} Action promise.
 */
const submitMeCreator =
  ({ country, gender, referralCode, kycId, contractId } = {}) =>
  async (dispatch, getState) => {
    const selectPath = ['submit', 'me', 'creator'];
    const token = getMeData(getState(), 'token');
    if (!token) {
      return dispatch({ type: '' });
    }
    const body = {
      country,
      gender,
      code: referralCode,
      kyc: kycId,
      contract: contractId,
    };
    Object.keys(body).forEach(key => {
      if (!body[key] && 'code' !== key) {
        delete body[key];
      }
    });
    const fetchOptions = {
      method: 'PUT',
      headers: {
        ...getHeaders(),
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(body),
    };
    const fetchUrl = getResourceUrl({ endpoint: '/me/creator' });
    dispatch({ type: SET_NETWORKING_FETCHING, payload: { selectPath } });
    try {
      let response = await fetch(fetchUrl.href, fetchOptions);
      if (!response.ok) {
        response = await handleFetchError({
          response,
          dispatch,
          getState,
          fetchOptions,
          fetchUrl,
        });
      }
      return dispatch({
        type: SET_NETWORKING_SUCCESS,
        payload: { selectPath },
      });
    } catch (error) {
      let errorObject = {};
      try {
        errorObject = JSON.parse(error.message);
        // eslint-disable-next-line no-empty
      } catch (_) {}
      if (errorObject.code) {
        const errorI18nKey = getTranslationKey({
          key: `error_${errorObject.code}`,
        });
        if ('PAYLOAD_VALIDATION_ERROR' === errorObject.code) {
          const validationErrorMessage = errorObject.message?.[0];
          if (validationErrorMessage) {
            Object.keys(validationErrorMessage).forEach(fieldName => {
              // Update acquisition error if error occurred on referral code.
              if ('code' === fieldName) {
                return dispatch({
                  type: MERGE_OPERATION_DATA,
                  payload: {
                    selectPath: ['acquisition'],
                    data: {
                      errorI18nkey: errorI18nKey,
                    },
                  },
                });
              }
              const joinField = ErrorMessageFieldAndJoinFieldMap[fieldName];
              if (!joinField) {
                return;
              }
              dispatch(
                setJoinDraftData({
                  key: joinField,
                  payload: {
                    error: {
                      i18nKey: errorI18nKey,
                    },
                  },
                })
              );
            });
          }
        }
        dispatch({
          type: MERGE_OPERATION_DATA,
          payload: {
            selectPath: ['join', 'draft', 'error'],
            data: {
              i18nKey: errorI18nKey,
            },
          },
        });
      }
      return dispatch({
        type: SET_NETWORKING_ERROR,
        payload: { selectPath, error },
      });
    }
  };

export default submitMeCreator;
